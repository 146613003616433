import * as yup from "yup";
import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "Recuerda llenar este campo",
    default: "No es válido",
  },
  number: {
    min: "Por favor elige un número más grande",
  },
});

const defaultValidators = {
  email: yup
    .string()
    .email("Por favor revisa el formato de tu correo")
    .required("Por favor escribe un correo"),
  phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe tu número celular")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError("Por favor escoge la ciudad donde recides"),
  instagram: yup
    .string()
    .max(50)
    .min(4, "Escribe un mínimo de 4 caracteres")
    .matches(
      /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim,
      "Por favor escribe un usuario de instagram válido (sin @), por ejemplo: fliping"
    ),
};

export const clientConfigSchema = yup.object().shape({
  first_name: yup.string().required("Por favor escribe tu nombre"),
  last_name: yup.string().required("Por favor escribe tu apellido"),
  email: defaultValidators.email,
  phone: defaultValidators.phone,
});

export const designerConfigSchema = yup.object().shape({
  email: defaultValidators.email,
  phone: defaultValidators.phone,
  // TODO: Uncomment this code when international virtual consultancy will be implemented
  //city_id: defaultValidators.city_id,
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError("Por favor escoge la ciudad donde recides"),
  bio: yup
    .string()
    .required()
    .min(170, "Escribe un mínimo de 170 caracteres describiendo tu estilo")
    .max(500, "Escribe un máximo de 500 caracteres describiendo tu estilo"),
  street_address: yup.string().required(),
  instagram: defaultValidators.instagram,
});

export const userConfigBankInfoSchema = yup.object().shape({
  bank_name: yup.string().required(),
  account_number: yup
    .string()
    .required("Por favor escribe el número de la cuenta"),
  is_savings_account: yup
    .string()
    .required("Por favor especifica el tipo de cuenta")
    .typeError("Por favor especifica el tipo de cuenta"),
});

export const trackingUrlSchema = yup.object().shape({
  url: yup
    .string()
    .url("Por favor escribe una url válida")
    .required("Por favor escribe una url"),
});

export const loginFormSchema = yup.object().shape({
  email: yup.string().email().required("Por favor escribe un correo"),
  password: yup.string().required("Por favor escribe una contraseña"),
});

export const supplierFormSchema = yup.object().shape({
  company_name: yup
    .string()
    .required("Por favor escribe el nombre de la empresa"),
  company_phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe el teléfono de la empresa")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),
  company_email: yup
    .string()
    .email()
    .required(
      "Por favor escribe el correo del servicio al cliente de la empresas"
    ),
  NIT: yup
    .string()
    .required("Por favor escribe el NIT")
    .length(9, "El NIT debe ser los primeros 9 dígitos")
    .matches(/^\d{9}$/, "Por favor revisa el formato de tu NIT"),
  city_name: yup
    .string()
    .required(
      "Por favor escoge una ciudad para la dirección de la oficina/facturación de la empresa"
    )
    .typeError("Por favor elige la ciudad en donde se encuentra la empresa"),
  warehouse1_city_name: yup
    .string()
    .required(
      "Por favor escoge una ciudad para la dirección de la bodega de despacho 1"
    )
    .typeError(
      "Por favor elige la ciudad en donde se encuentra la bodega de despacho"
    ),
  street_address: yup
    .string()
    .required("Por favor escribe la dirección de la empresa"),
  warehouse1_address: yup
    .string()
    .required("Por favor escribe como mínimo una dirección de despacho"),
  password: yup.string().required("Por favor escribe una contraseña"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Por favor confirma tu contraseña")
    .nullable(),
  company_description: yup
    .string()
    .required("Por favor escribe una descripción de tus productos")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tus productos")
    .max(250, "Escribe un máximo de 250 caracteres describiendo tus productos"),
  legal_rep_first_name: yup
    .string()
    .required("Por favor escribe el nombre del representante legal"),
  legal_rep_last_name: yup
    .string()
    .required("Por favor escribe el apellido del representante legal"),
  legal_rep_phone: yup
    .string()
    .required("Por favor escribe el número de celular del representante legal")
    .length(10, "El número celular debe ser de 10 dígitos")
    .matches(
      /^\d{10}$/,
      "Por favor revisa el formato del número celular del representante legal"
    ),
  legal_rep_cedula: yup
    .string()
    .required("Por favor escribe la cédula del representante legal")
    .matches(/^[0-9]{7,10}$/, "Por favor revisa el formato de tu cédula"),
});

export const supplierFormSchema2 = yup.object().shape({
  contact_first_name: yup.string().required("Por favor escribe tu nombre"),
  contact_last_name: yup.string().required("Por favor escribe tu apellido"),
  contact_email: yup.string().email().required("Por favor escribe tu correo"),
  contact_phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe tu número de celular")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),
});

export const ecommercePreferencesSchema = yup.object().shape({
  company_primary_color: yup.string().required("Color primario es requerido"),
  company_secondary_color: yup
    .string()
    .required("Color secundario es requerido"),
  company_font_type: yup.string().required("Por favor elige una tipografia"),
});

export const wompiKeySchema = yup.object().shape({
  publicKey: yup.string().required("La llave pública es requerida"),
});

export const salesPersonSchema = (type: "create" | "update") =>
  yup.object().shape({
    avatar: yup
      .mixed()
      .test(
        "fileType",
        "El archivo debe ser una imagen (jpg, jpeg, png, gif, webp)",
        (value) => {
          if (!value) return false;
          const file = Array.isArray(value) ? value[0] : value;
          if (typeof file === "string") {
            return true;
          }
          return (
            file &&
            ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(
              file.type
            )
          );
        }
      )
      .test("fileSize", "El archivo no debe pesar más de 15 MB", (value) => {
        if (!value) return false;
        const file = Array.isArray(value) ? value[0] : value;
        if (typeof file === "string") {
          return true;
        }
        return file && file.size <= 15 * 1024 * 1024; // 15 MB in bytes
      }),
    first_name: yup
      .string()
      .min(4, "El nombre debe tener al menos 4 caracteres")
      .required("Por favor escribe tu nombre"),
    last_name: yup
      .string()
      .min(4, "El apellido debe tener al menos 4 caracteres")
      .required("Por favor escribe tu apellido"),
    email: yup.string().email().required("Por favor escribe tu correo"),
    phone: yup
      .string()
      .length(10, "El número celular debe ser de 10 dígitos")
      .required("Por favor escribe tu número de WhatsApp"),
    city_name: yup
      .string()
      .required("Por favor escoge una ciudad")
      .typeError(
        "Por favor elige la ciudad en donde se realizará la remodelación"
      ),
    professional_profile: yup
      .string()
      .required("Por favor escribe tu perfil profesional")
      .min(5, "Escribe un mínimo de 70 caracteres describiendo tu perfil")
      .max(500, "Escribe un máximo de 500 caracteres describiendo tu perfil"),
    password:
      type === "create"
        ? yup
            .string()
            .required("Por favor escribe una contraseña")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .matches(
              /[A-Z]/,
              "La contraseña debe contener al menos una letra mayúscula"
            )
            .matches(
              /[a-z]/,
              "La contraseña debe contener al menos una letra minúscula"
            )
            .matches(/[0-9]/, "La contraseña debe contener al menos un número")
            .matches(
              /[!@#$%^&*(),.?":{}|<>]/,
              "La contraseña debe contener al menos un carácter especial"
            )
        : yup.string().notRequired(),
    passwordConfirm:
      type === "create"
        ? yup
            .string()
            .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
            .required("Por favor confirma tu contraseña")
            .nullable()
        : yup.string().notRequired().nullable(),
    skills_description: yup
      .string()
      .min(
        48,
        "La descripción de tus habilidades debe tener al menos 48 caracteres"
      )
      .max(
        500,
        "La descripción de tus habilidades debe tener maximo 500 caracteres"
      )
      .required("Por favor escribe una descripción de tus habilidades"),
  });

export const salesPersonContactSchema = yup.object().shape({
  name: yup.string().required("Nombre completo es requerido"),
  email: yup
    .string()
    .email("Correo electrónico no válido")
    .required("Correo electrónico es requerido"),
  phone: yup
    .string()
    .matches(/^\d+$/, "Número de WhatsApp no válido")
    .length(10, "El número de WhatsApp debe tener exactamente 10 dígitos")
    .required("Número de WhatsApp es requerido"),
});

export const navbarLinksFormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  url: yup.string().url("Invalid URL").required("URL is required"),
});

export const warehousesSchema = yup.object().shape({
  street_address: yup.string().required("Por favor escribe la dirección"),
  city_name: yup.string().required("Por favor selecciona una ciudad"),
});

export const signupFormSchema = yup.object().shape({
  first_name: yup.string().required("Por favor escribe tu nombre"),
  last_name: yup.string().required("Por favor escribe tu apellido"),
  cedula: yup
    .string()
    .required("Por favor escribe tu cedula")
    .matches(/^[0-9]{7,10}$/, "Por favor revisa el formato de tu cédula"),
  email: yup.string().email().required("Por favor escribe tu correo"),
  password: yup.string().required("Por favor escribe una contraseña"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Por favor confirma tu contraseña")
    .nullable(),
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError(
      "Por favor elige la ciudad en donde se realizará la remodelación"
    ),
  phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe tu número de WhatsApp")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),
  street_address: yup
    .string()
    .required("Por favor escribe la dirección de la remodelación"),
  referred_from_id: yup
    .number()
    .required("Cuentanos cómo conociste Fliping")
    .typeError("Cuentanos cómo conociste Fliping"),
});

export const clientReceiptPersonForm = yup.object().shape({
  first_name: yup.string().required("Por favor escribe tu nombre"),
  last_name: yup.string().required("Por favor escribe tu apellido"),
  document_number: yup
    .string()
    .required("Por favor escribe tu número de documento")
    .matches(/^[0-9]{7,10}$/, "Por favor revisa el formato de tu documento"),
  email: yup.string().email().required("Por favor escribe tu correo"),
  address: yup.string().required("Por favor escribe tu dirección"),
  city: yup
    .string()
    .required("Por favor escribe la ciudad")
    .min(4, "Escribe un mínimo de 4 caracteres"),
});

export const clientReceiptCompanyForm = yup.object().shape({
  company_name: yup.string().required("Por favor escribe la Razón Social"),
  NIT: yup
    .string()
    .required("Por favor escribe tu número de NIT")
    .matches(
      /^\d{9}$/,
      "Por favor revisa el formato del NIT,sin caracteres especiales"
    ),
  company_address: yup
    .string()
    .required("Por favor escribe la dirección de tu compañía"),
});

export const signupFormSchemaVirtual = yup.object().shape({
  first_name: yup.string().required("Por favor escribe tu nombre"),
  last_name: yup.string().required("Por favor escribe tu apellido"),
  phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe tu número de WhatsApp")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),

  email: yup.string().email().required("Por favor escribe tu correo"),
  password: yup
    .string()
    .required("Por favor escribe una contraseña")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "La contraseña debe tener al menos una letra mayúscula, una letra minúscula, un caracter especial, un número y 8 caracteres"
    ),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Por favor confirma tu contraseña")
    .nullable(),
  referred_from_id: yup
    .number()
    .required("Cuentanos cómo conociste Fliping")
    .typeError("Cuentanos cómo conociste Fliping"),
});

export const returningClientFormSchema = yup.object().shape({
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError(
      "Por favor elige la ciudad en donde se realizará la remodelación"
    ),
  street_address: yup
    .string()
    .required("Por favor escribe la dirección de la remodelación"),
});

export const signupFormSchemaLoggedInUser = yup.object().shape({
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError(
      "Por favor elige la ciudad en donde se realizará la remodelación"
    ),
  address: yup
    .string()
    .required("Por favor escribe la dirección de la remodelación"),
});

export const coatingFormSchema = yup.object().shape({
  code: yup.string(),
  brand: yup.string().required("Por favor escoge una marca"),
  name: yup.string().required("Por favor ingresa la referencia del producto"),
  min_sale_unit: yup
    .number()
    .positive("La unidad mínima de venta en m² debe ser un número mayor que 0")

    .typeError(
      "Por favor ingresa una unidad mínima de venta en m². Puede ser entero o decimal con punto en vez de coma."
    ),
  price: yup
    .string()
    .required("Por favor ingresa un precio")
    .typeError("Por favor ingresa un precio válido sin punto ni comas")
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa un precio válido sin puntos ni comas"
    ),
  comission_percentage: yup
    .number()
    .required("Por favor elige un porcentage de comisión")
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .typeError("El porcentaje de comisión debe ser un número"),
  storage_address_id: yup
    .number()
    .positive("Por favor elige una dirección de despacho")
    .required("Por favor elige una dirección de despacho")
    .typeError("Por favor elige una dirección de despacho"),
  units_available: yup
    .number()
    .required("Por favor especifica las unidades en inventario")
    .positive("Las unidades disponibles deben ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número"),
  warranty: yup
    .string()
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor especifica los datos de garantía sin puntos ni comas"
    )
    .notRequired(),
  is_warranty_in_months: yup.number().notRequired(),

  description: yup
    .string()
    .required("Por favor ingresa la descripción del revestimiento")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tu producto")
    .max(500, "La descripción debe ser de máximo 500 carácteres"),
  length: yup
    .number()
    .required()
    .positive("La longitud debe ser mayor que 0")
    .typeError(
      "Por favor ingresa para longitud un valor entero o decimal con punto en vez de coma."
    ),
  width: yup
    .number()
    .required()
    .positive("El ancho debe ser mayor que 0")
    .typeError(
      "Por favor ingresa para ancho un valor entero o decimal con punto en vez de coma."
    ),
  units_per_package: yup
    .number()
    .integer("Las unidades por empaque deben ser un número entero")
    .required()
    .positive("Las unidades por empaque deben ser mayor que 0")
    .typeError("Por favor ingresa para unidades por empaque un valor entero."),
});

export const complementaryFormSchema = yup.object().shape({
  code: yup.string(),
  brand: yup.string().required("Por favor escoge una marca"),
  name: yup.string().required("Por favor escribe la referencia del producto"),
  brand_that_complements_id: yup
    .number()
    .required("Por favor escoge una marca que complementa"),
  min_sale_unit: yup
    .string()

    .typeError(
      "Por favor ingresa una unidad mínima de venta sin puntos ni comas"
    )
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa una unidad mínima de venta sin puntos ni comas"
    ),
  unit_of_measure_id: yup
    .string()
    .required("Por favor escoge una unidad de medida"),
  yield_m2: yup
    .number()
    .positive("El rendimiento por unidad en m² debe ser mayor que 0")
    .required()
    .typeError(
      "Por favor ingresa un valor de rendimiento por unidad en m², ya sea entero o decimal con punto en vez de coma"
    ),
  units_available: yup
    .number()
    .required("Por favor especifica las unidades en inventario")
    .positive("Las unidades disponibles deben ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número"),
  price: yup
    .string()
    .required("Por favor ingresa un precio")
    .typeError("Por favor ingresa un precio válido sin puntos ni comas")
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa un precio válido sin puntos ni comas"
    ),
  comission_percentage: yup
    .number()
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .required("Por favor elige un porcentage de comisión")
    .typeError("El porcentaje de comisión debe ser un número"),
  storage_address_id: yup
    .number()
    .positive("Por favor elige una dirección de despacho")
    .required("Por favor elige una dirección de despacho")
    .typeError("Por favor elige una dirección de despacho"),
  description: yup
    .string()
    .required("Por favor escribe la descripción del producto")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tu producto")
    .max(500, "La descripción debe ser de máximo 500 carácteres"),
  units_per_package: yup
    .number()
    .integer("Las unidades por empaque deben ser un número entero")
    .required()
    .positive("Las unidades por empaque deben ser mayor que 0")
    .typeError("Por favor ingresa para unidades por empaque un valor entero."),
});

export const decorativeFormSchema = yup.object().shape({
  name: yup
    .string()
    .min(5, "El título debe ser de mínimo 10 carácteres")
    .max(25, "El título debe ser de máximo 15 carácteres")
    .required("Por favor ingresa el título del producto"),
  brand: yup
    .string()
    .min(3, "La marca debe ser de mínimo 3 carácteres")
    .required("Por favor ingresa la marca"),
  reference: yup
    .string()
    .max(50, "La referencia debe ser de máximo 50 carácteres")
    .min(3, "La referencia debe ser de mínimo 3 carácteres")
    .required("Por favor ingresa la referencia del producto"),
  category_id: yup.number().required("Por favor escoge el tipo de producto"),
  min_sale_unit: yup
    .string()

    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa una unidad mínima de venta sin puntos ni comas"
    ),
  price: yup
    .string()
    .required("Por favor ingresa un precio")
    .typeError("Por favor ingresa un precio válido sin puntos ni comas")
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa un precio válido sin puntos ni comas"
    ),
  comission_percentage: yup
    .number()
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .required("Por favor elige un porcentage de comisión")
    .typeError("El porcentaje de comisión debe ser un número"),
  storage_address_id: yup
    .number()
    .positive("Por favor elige una dirección de despacho")
    .required("Por favor elige una dirección de despacho")
    .typeError("Por favor elige una dirección de despacho"),
  units_available: yup
    .number()
    .required("Por favor especifica las unidades en inventario")
    .positive("Las unidades disponibles deben ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número"),
  warranty: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresar los datos de garantía del producto sin puntos ni comas"
    )
    .notRequired(),
  is_unique: yup
    .string()
    .required("Por favor elige si el producto es único")
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona si el producto es único"),
  is_warranty_in_months: yup.number().notRequired(),
  delivery_time: yup
    .number()
    .required("Por favor elige el tiempo de entrega de tu producto")
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número")
    .max(30, "El tiempo de entrega debe ser menor a 30 días"),
  description: yup
    .string()
    .required("Por favor escribe la descripción del producto")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tu producto")
    .max(500, "La descripción debe ser de máximo 500 carácteres"),
  length: yup
    .number()
    .required()
    .typeError(
      "Por favor ingresa para la longitud ,un valor entero o decimal con punto en vez de coma"
    ),
  width: yup
    .number()
    .required()
    .typeError(
      "Por favor ingresa para el ancho , un valor entero o decimal con punto en vez de coma"
    ),
  depth: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para profundidad, un valor entero o decimal con punto en vez de coma"
    ),
  height: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para altura, un valor entero o decimal con punto en vez de coma"
    ),
  thickness: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para el espesor ,un valor entero o decimal con punto en vez de coma"
    ),
  diameter: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para el diametro ,un valor entero o decimal con punto en vez de coma"
    ),
  units_per_package: yup
    .number()
    .integer("Las unidades por empaque deben ser un número entero")
    .required()
    .positive("Las unidades por empaque deben ser mayor que 0")
    .typeError("Por favor ingresa para unidades por empaque un valor entero."),
});
export const productFormSchema = yup.object().shape({
  name: yup
    .string()
    .min(5, "El título debe ser de mínimo 5 carácteres")
    .max(15, "El título debe ser de máximo 15 carácteres")
    .required("Por favor ingresa el título del producto"),
  brand: yup
    .string()
    .min(3, "La marca debe ser de mínimo 3 carácteres")
    .required("Por favor ingresa la marca"),
  color_id: yup
    .number()
    .required("Por favor escoge el color del producto")
    .typeError("Por favor escoge el color del producto"),
  material: yup
    .string()
    .min(3, "El material debe ser de mínimo 3 carácteres")
    .max(25, "El material debe ser de máximo 25 carácteres")
    .required("Por favor ingresa el material"),
  category_id: yup.string().required("Por favor escoge el tipo de producto"),
  min_sale_unit: yup
    .number()
    .positive("La unidad mínima de venta en m² debe ser un número mayor que 0")
    .typeError(
      "Por favor ingresa una unidad mínima de venta en m². Puede ser entero o decimal con punto en vez de coma."
    ),
  price: yup
    .string()
    .required("Por favor ingresa un precio")
    .typeError("Por favor ingresa un precio válido sin puntos ni comas")
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa un precio válido sin puntos ni comas"
    ),
  comission_percentage: yup
    .number()
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .required("Por favor elige un porcentage de comisión")
    .typeError("El porcentaje de comisión debe ser un número"),
  storage_address_id: yup
    .number()
    .positive("Por favor elige una dirección de despacho")
    .required("Por favor elige una dirección de despacho")
    .typeError("Por favor elige una dirección de despacho"),
  units_available: yup
    .number()
    .required("Por favor especifica las unidades en inventario")
    .positive("Las unidades disponibles deben ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número"),
  warranty: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresar los datos de garantía del producto sin puntos ni comas"
    )
    .notRequired(),
  is_unique: yup
    .string()
    .required("Por favor elige si el producto es único")
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona si el producto es único"),
  is_warranty_in_months: yup.number().notRequired(),
  delivery_time: yup
    .number()
    .required("Por favor elige el tiempo de entrega de tu producto")
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .max(30, "El tiempo de entrega debe ser menor a 30 días")
    .typeError("El tiempo de entrega debe ser un número"),
  description: yup
    .string()
    .required("Por favor escribe la descripción del producto")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tu producto")
    .max(500, "La descripción debe ser de máximo 500 carácteres"),
  units_per_package: yup
    .number()
    .integer("Las unidades por empaque deben ser un número entero")
    .required()
    .positive("Las unidades por empaque deben ser mayor que 0")
    .typeError("Por favor ingresa para unidades por empaque un valor entero."),
});

export const commonAttributesSchema = yup.object().shape({
  name: yup
    .string()
    .required("Por favor ingresa el nombre del producto")
    .min(2, "El nombre debe ser de mínimo 2 caracteres")
    .max(40, "El título debe ser de máximo 40 caracteres")
    .matches(/^[a-zA-Z0-9\s]*$/, "No se permiten caracteres especiales"),
  brand: yup
    .string()
    .required("Por favor ingresa la marca")
    .min(2, "La marca debe ser de mínimo 2 caracteres")
    .max(25, "El título debe ser de máximo 25 caracteres"),
  material: yup
    .string()
    .required("Por favor ingresa el material")
    .min(2, "El material debe ser de mínimo 2 carácteres")
    .max(25, "El material debe ser de máximo 25 carácteres"),
  category_id: yup.string().required("Por favor escoge el tipo de producto"),
  price: yup
    .string()
    .required("Por favor ingresa un precio")
    .typeError("Por favor ingresa un precio válido sin puntos ni comas")
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresa un precio válido sin puntos ni comas"
    ),
  units_available: yup
    .string()
    .required("Por favor especifica las unidades en inventario")
    .matches(/^[0-9]+$/, "Por favor ingresa solo números")
    .test(
      "no-special-characters",
      "Por favor no incluyas puntos ni comas",
      (value) => {
        if (value) {
          return !/[.,]/.test(value);
        }
        return true;
      }
    ),
  comission_percentage: yup
    .number()
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .required("Por favor elige un porcentaje de comisión")
    .typeError("El porcentaje de comisión debe ser un número"),
  storage_address_id: yup
    .number()
    .positive("Por favor elige una dirección de despacho")
    .required("Por favor elige una dirección de despacho")
    .typeError("Por favor elige una dirección de despacho"),
  warranty: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value))
    .matches(
      /^([1-9]\d*|0)$/,
      "Por favor ingresar los datos de garantía del producto sin puntos ni comas"
    )
    .notRequired(),

  is_warranty_in_months: yup.number().notRequired(),
  delivery_time: yup
    .number()
    .required("Por favor elige el tiempo de entrega de tu producto")
    .positive("El porcentaje de comisión debe ser un número mayor que 0")
    .typeError("El tiempo de entrega debe ser un número")
    .max(30, "El tiempo de entrega debe ser menor a 30 días"),
  description: yup
    .string()
    .required("Por favor escribe la descripción del producto")
    .min(70, "Escribe un mínimo de 70 caracteres describiendo tu producto")
    .max(500, "La descripción debe ser de máximo 500 caracteres"),
  color_id: yup
    .number()
    .required("Por favor escoge el color del producto")
    .typeError("Por favor escoge el color del producto"),
});

export const coatingAndFlooringAttributesSchema = yup.object().shape({
  min_sale_unit: yup
    .number()
    .positive("La unidad mínima de venta en m² debe ser un número mayor que 0")
    .required("Por favor ingresa una unidad mínima de venta en m²")
    .typeError(
      "Por favor ingresa una unidad mínima de venta en m². Puede ser entero o decimal con punto en vez de coma."
    ),
  units_per_package: yup
    .number()
    .integer("Las unidades por empaque deben ser un número entero")
    .required()
    .positive("Las unidades por empaque deben ser mayor que 0")
    .typeError("Por favor ingresa para unidades por empaque un valor entero."),
});

export const notCoatingAndFlooringAttributesSchema = yup.object().shape({
  is_unique: yup
    .string()
    .required("Por favor elige si el producto es único")
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona si el producto es único"),
});

export const lightingAttributesSchema = yup.object().shape({
  is_unique: yup
    .string()
    .required("Por favor elige si el producto es único")
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona si el producto es único"),
  light_type_id: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona un tipo de luz de tu producto"),
  lamp_type_id: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError("Por favor selecciona un tipo de lámpara de tu producto"),
});

export const homeAndWellnessAttributesSchema = yup.object().shape({
  size: yup
    .string()
    .nullable()
    .max(3, "La talla debe ser de máximo 3 carácteres"),
  origin: yup
    .string()
    .nullable()
    .required("Por favor selecciona el origen del producto"),
  product_content: yup
    .string()
    .nullable()
    .required("Por favor ingresa el contenido del producto"),
});

export const petsAttributesSchema = yup.object().shape({
  origin: yup
    .string()
    .nullable()
    .required("Por favor selecciona el origen del producto"),
  product_content: yup
    .string()
    .nullable()
    .required("Por favor ingresa el contenido del producto"),
});

export const coatingAndFlooringDimensionsSchema = yup.object().shape({
  length: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un número entero o un decimal (usa un punto, no una coma)."
    ),
  width: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un número entero o un decimal (usa un punto, no una coma)."
    ),
  thickness: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un número entero o un decimal (usa un punto, no una coma)."
    ),
  weight: yup
    .number()
    .required()
    .positive("El peso debe ser mayor que 0")
    .typeError(
      "Por favor ingresa para peso un valor entero o decimal con punto en vez de coma."
    ),
});

export const notCoatingAndFlooringDimensionsSchema = yup.object().shape({
  length: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  width: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  depth: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  height: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  diameter: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  weight: yup
    .number()
    .required()
    .positive("El peso debe ser mayor que 0")
    .typeError(
      "Por favor ingresa para peso un valor entero o decimal con punto en vez de coma."
    ),
});

export const packageDimensionsSchema = yup.object().shape({
  package_length: yup
    .number()
    .required("Por favor ingresa la longitud del empaque")
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  package_width: yup
    .number()
    .required("Por favor ingresa el ancho del empaque")
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  package_height: yup
    .number()
    .required("Por favor ingresa la altura del empaque")
    .typeError(
      "Por favor ingresa para esta dimensión un valor entero o decimal con punto en vez de coma"
    ),
  package_weight: yup
    .number()
    .required("Por favor ingresa un peso en kg del empaque")
    .typeError(
      "Por favor ingresa un peso en kg del empaque. Puedes usar un número entero o un decimal (usa un punto, no una coma)."
    ),
  packing_presentation_id: yup
    .number()
    .required("Por favor selecciona un tipo de empaque")
    .typeError("Por favor selecciona un tipo de empaque"),
});

export const designerFormSchema = yup.object().shape({
  first_name: yup.string().required("Por favor escribe tu nombre"),
  last_name: yup.string().required("Por favor escribe tu apellido"),
  email: yup.string().email().required("Por favor escribe tu correo"),
  cedula: yup
    .string()
    .required("Por favor escribe tu cédula")
    .matches(/^[0-9]{7,10}$/, "Por favor revisa el formato de tu cédula"),
  phone: yup
    .string()
    .length(10, "El número celular debe ser de 10 dígitos")
    .required("Por favor escribe tu número de WhatsApp")
    .matches(/^\d{10}$/, "Por favor revisa el formato de tu número celular"),
  bio: yup
    .string()
    .required("Por favor escribe una descripción de tu trabajo")
    .min(170, "Escribe un mínimo de 170 caracteres describiendo tu estilo")
    .max(500, "Escribe un máximo de 500 caracteres describiendo tu estilo"),
  city_name: yup
    .string()
    .required("Por favor escoge una ciudad")
    .typeError("Por favor escoge la ciudad donde recides"),
  profession_id: yup
    .number()
    .required("Por favor elige una profesión")
    .typeError("Por favor elige la profesión que más te describe"),
  street_address: yup.string().required("Por favor escribe tu dirección"),
  password: yup.string().required("Por favor escribe una contraseña"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Por favor confirma tu contraseña")
    .nullable(),
});

export const IndividualSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Ingrese solo letras en el campo de nombre")
    .required("Por favor, ingrese su nombre"),
  last_name: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Ingrese solo letras en el campo de apellido")
    .required("Por favor, ingrese su apellido"),
  legal_document_type: yup
    .string()
    .required("Por favor, seleccione un tipo de documento"),
  identification_code: yup
    .string()
    .matches(
      /^[0-9]+$/,
      'Ingrese solo números en el campo de "Número de documento"'
    )
    .required("Por favor, ingrese su código de identificación"),
});

export const AddressSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  city: yup.string().required(),
  street_address: yup.string().required(),
  neighborhood: yup.string().required(),
  phone: yup.string().required(),
  comments: yup.string().required(),
});

export const CompanySchema = yup.object().shape({
  company_name: yup
    .string()
    .required("Por favor, ingrese el nombre de su compañía"),
  NIT: yup
    .string()
    .matches(/^[0-9]+$/, "Ingrese solo números en el campo de NIT")
    .required("Por favor, ingrese su NIT"),
});

export const principalImageSchema = yup.object().shape({
  cover: yup
    .mixed()
    .required("La imagen principal es un campo requerido.")
    .test(
      "fileType",
      "El archivo debe ser una imagen (jpg, jpeg, png, gif, webp)",
      (value) => {
        if (!value) return false;
        const file = Array.isArray(value) ? value[0] : value;
        return (
          file &&
          ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(
            file.type
          )
        );
      }
    )
    .test("fileSize", "El archivo no debe pesar más de 15 MB", (value) => {
      if (!value) return false;
      const file = Array.isArray(value) ? value[0] : value;
      return file && file.size <= 15 * 1024 * 1024; // 15 MB in bytes
    }),
});

export const ambientImagesSchema = yup.object().shape({
  ambient: yup
    .mixed()
    .required("Las imágenes de ambiente son un campo requerido.")
    .test(
      "maxFiles",
      "Has superado el límite permitido. Sólo puedes subir hasta 5 imágenes.",
      function (value) {
        if (!value) return false;
        // We ensure that value is treated as FileList
        const files = value as FileList;
        return files.length <= 5;
      }
    )
    .test(
      "fileType",
      "Todos los archivos deben ser imágenes (jpg, jpeg, png, gif, webp)",
      function (value) {
        if (!value) return false;
        // We ensure that value is treated as FileList
        const files = value as FileList;
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/webp",
        ];
        return Array.from(files).every((file) =>
          allowedTypes.includes(file.type)
        );
      }
    )
    .test("fileSize", "El archivo no debe pesar más de 15 MB", (value) => {
      if (!value) return false;
      const file = Array.isArray(value) ? value[0] : value;
      return file && file.size <= 15 * 1024 * 1024; // 15 MB in bytes
    }),
});

// TODO: add validation depending on the document type
export const receiptPersonSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  document_number: yup
    .string()
    .matches(/^[0-9]{7,10}$/, "Por favor revisa el formato de tu documento")
    .required(),
});

// TODO: add NIT specific validation
export const receiptCompanySchema = yup.object().shape({
  business_name: yup.string().required(),
  NIT: yup
    .string()
    .required("Por favor escribe tu número de NIT")
    .matches(
      /^\d{9}$/,
      "Por favor revisa el formato del NIT,sin caracteres especiales"
    ),
});

/*
 * Validates maximum size and extension of a single file.
 * The default max size is 15MB
 */
export const validateFile = (
  file: File,
  fileExtension: RegExp,
  maxSize = 15 * 1024 * 1024
) => {
  let isValidSize = file.size <= maxSize;
  let isValidExtension = fileExtension.test(file.type);

  if (!isValidExtension) {
    throw new Error(
      `Por favor revisa el formato del archivo ${file.name}, formatos aceptados: ${fileExtension}`
    );
  }
  if (!isValidSize) {
    throw new Error(
      `El archivo ${file.name} es demasiado pesado, por favor intenta subir uno con menor resolución`
    );
  }
  return true;
};

/*
 * Return the number of entered dimensions for a product.
 */
export const numDimensionsEntered = (
  category: number,
  width: number | null = null,
  height: number | null = null,
  length: number | null = null,
  depth: number | null = null,
  thickness: number | null = null,
  diameter: number | null = null
) => {
  let count = 0;
  if (category !== 8 && category !== 9) {
    count =
      (length !== null && length > 0 ? 1 : 0) +
      (width !== null && width > 0 ? 1 : 0) +
      (height !== null && height > 0 ? 1 : 0) +
      (depth !== null && depth > 0 ? 1 : 0) +
      (diameter !== null && diameter > 0 ? 1 : 0);
  } else if (category === 8 || category === 9) {
    count =
      (length !== null && length > 0 ? 1 : 0) +
      (width !== null && width > 0 ? 1 : 0) +
      (thickness !== null && thickness > 0 ? 1 : 0);
  }

  return count;
};
