export const virtualConsultancySidebarLinks = [
  {
    name: "Ver productos",
    route: "/productos",
    targetBlank: false,
  },
  {
    name: "Solicitar Asesoría",
    route: "https://fliping.co/elige-profesional/",
    targetBlank: true,
  },
  {
    name: "Trabaja con Fliping",
    route: "https://fliping.co/trabaja-con-nosotros-disenador/",
    targetBlank: true,
  },
  {
    name: "Inspírate",
    route: "https://fliping.co/#inspirate",
    targetBlank: true,
  },
];

export const originTypes = [
  { id: 1, name: "Nacional" },
  { id: 2, name: "Internacional" },
];

export const lightTypes = [
  { id: 1, name: "Cálida" },
  { id: 2, name: "Neutra" },
  { id: 3, name: "Fría" },
];

export const lampTypes = [
  { id: 1, name: "Apliques de pared" },
  { id: 2, name: "Lámparas de techo" },
  { id: 3, name: "Lámparas de mesa" },
  { id: 4, name: "Lámparas de piso" },
  { id: 5, name: "Iluminación especial" },
  { id: 6, name: "Bombillos" },
];

export const editProductTabs = [
  { id: "general", label: "Información General" },
  { id: "measurements", label: " Dimensiones Producto" },
  { id: "packagingMasures", label: "Dimensiones Empaque" },
  { id: "images", label: "Imágenes" },
  { id: "technicalSheet", label: "Ficha Técnica" },
];

export const packageTypes = [
  { id: 1, name: "Caja" },
  { id: 2, name: "Sobre" },
];

export const principalImageParagraphs = [
  "Selecciona una imagen principal de alta calidad y con un fondo limpio para resaltar tu producto.",
  "Proporciona múltiples ángulos y detalles para una experiencia de visualización completa.",
  "Mantén un tamaño uniforme y coherente en todas tus imágenes para una presentación profesional y atractiva.",
];

export const ambientImageParagraphs = [
  "Agrega imágenes complementarias para mostrar tu producto en diferentes ambientes y situaciones.",
  "Asegúrate de que las imágenes sean claras y nítidas, y que muestren el producto desde diferentes ángulos y perspectivas.",
  "Agrega máximo 5 imágenes complementarias para mostrar tu producto.",
];
